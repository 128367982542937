import React from 'react';
import moment from 'moment';
import '../../css/main.css';

// max time that can be added to the meter in minutes
const maxAddedTime = 360;

class AddTime extends React.Component {
    constructor(props) {
        super(props);
        this.resetState = this.resetState.bind(this);
        this.selectPay = this.selectPay.bind(this); 
        this.pay = this.pay.bind(this); 
        this.confirm = this.confirm.bind(this);
        this.addTime = this.addTime.bind(this);
        this.onConfirm = this.props.onConfirm.bind(this);
        this.incrementTime = this.incrementTime.bind(this);
        this.decrementTime = this.decrementTime.bind(this); 

        this.state = {
            totalCost: 0.0, 
            // amountPaid: 0.0, 
            newExpiry: this.props.expiry.clone(),
            additionalDuration: moment.duration(0),
            decrementButton: (<button disabled>-</button>),
            incrementButton: <button onClick={this.incrementTime}>+</button>,
            mode: 0 
        };
        this.priceIncrement = Math.round(this.props.rate * (this.props.addTime.asMinutes() / 60));
    }

    static getDerivedStateFromProps(props, state) {
        if (moment.duration(props.expiry.diff(moment())) > 0 && state.additionalDuration <= 0) {
            return { newExpiry: props.expiry.clone() };
        } else if (moment.duration(state.newExpiry.diff(moment())) < 0) {
            return { newExpiry: moment() };
        } else {
            return null;
        }
    }

    componentDidUpdate() {
        this.priceIncrement = Math.round(this.props.rate * (this.props.addTime.asMinutes() / 60));
    }

    resetState() {
        this.setState({
            totalCost: 0.0,
            newExpiry: this.props.expiry,
            additionalDuration: moment.duration(0),
        });
    }

    incrementTime() {
        this.setState({
            totalCost: this.state.totalCost + this.priceIncrement,
            newExpiry: this.state.newExpiry.clone().add(this.props.addTime),
            additionalDuration: this.state.additionalDuration.add(this.props.addTime),
            decrementButton: <button onClick={this.decrementTime}>-</button>
        });

        if (moment.duration(this.state.newExpiry.diff(moment())).asMinutes() >= maxAddedTime) {
            this.setState({ incrementButton: <button disabled>+</button> });
        }
    }

    decrementTime() {
        if (this.state.additionalDuration.asMinutes() > 0) {
            this.setState({
                totalCost: this.state.totalCost - this.priceIncrement,
                newExpiry: this.state.newExpiry.subtract(this.props.addTime),
                additionalDuration: this.state.additionalDuration.subtract(this.props.addTime),
                incrementButton: <button onClick={this.incrementTime}>+</button>
            });
        }
        if (this.state.additionalDuration.asMinutes() <= 0) {
            this.setState({ decrementButton: <button disabled>-</button> });
        }
    }

    selectPay(){
        this.setState({mode: 1}); 
    }

    pay(){
        this.setState({mode: 2});
    }

    confirm() {
        this.onConfirm(this.state.totalCost, this.state.newExpiry);
        // this.resetState();
        this.setState({
            newExpiry: this.props.expiry,
            additionalDuration: moment.duration(0),
            mode: 3
        });
    }

    addTime() { 
        this.setState({
            totalCost: 0.0, 
            mode: 0
        }); 
    }



    render() {
        if (this.state.mode==0){ // Add Time  
            return (
                <section className="add-time">
                    <div className="container">
                        <div className="incrementor">
                            <div className="subtract-button">
                                {this.state.decrementButton}
                            </div>
                            <div className="subtotal">
                                <div className="countdown-display">
                                    <span>{this.state.additionalDuration.asMinutes()} minutes</span>
                                </div>
                                <div className="expiry-display">
                                    <span>Expires {this.state.newExpiry.format("hh:mm A")}</span>
                                </div>
                            </div>
                            <div className="add-button">
                                {this.state.incrementButton}
                            </div>
                        </div>
                        <div className="confirm-button">
                            <button onClick={this.selectPay}>
                                <div className="confirm-price">
                                    ${(this.state.totalCost/100).toFixed(2)}
                                </div>
                                <div className="confirm-message">
                                    Tap to Confirm
                                </div>
                            </button>
                        </div>
                    </div>
                </section>
            )
        }
        if (this.state.mode==1){ // Select Pay 
            return (
                <section className="payment">
                    <h1 className="header">Subtotal:  ${(this.state.totalCost/100).toFixed(2)}</h1>
                    <div className="container"> 
                        <button onClick={this.pay}>Apple Pay</button>
                    </div>
                    <div className="container">
                        <button onClick={this.pay}>Google Pay</button>
                    </div>
                    <div className="container">
                        <button onClick={this.pay}>Credit Card</button>
                    </div>
                </section>
            )
        }
        if (this.state.mode==2){ // Pay 
            return (
                <section className="payment">
                    <h1 className="header">Subtotal:  ${this.state.totalCost / 100}</h1>
                    <div className="container">
                        <h2> ... </h2>
                        <h2>...stripe stuff goes here... </h2>
                        <h2> ... </h2>
                        <button onClick={this.confirm}>Confirm Payment</button>
                    </div>
                </section>
            )
        }
        if (this.state.mode==3){ // Confirm Pay 
            return (
                <section className="payment">
                    <div className="thankyou">Thank You! </div> 
                    <div className="confirmation"> <span className="highlight">Confirmation ID:</span> C-132 </div>
                    <div className="confirmation"> <span className="highlight">Date:</span> {moment().format('MMMM DD YYYY, h:mm a')}</div>
                    <div className="confirmation"> <span className="highlight">Amount Paid:</span> ${(this.state.totalCost/100).toFixed(2)}</div>
                    <div className="confirmation">  </div>
                    <div className="confirmation">  </div>
                    <div className="container"> 
                        <button onClick={this.addTime}>Return to Meter</button>
                    </div>
                </section>
            )
        }
    }
}

export { AddTime }

