import React from 'react';
import moment from 'moment';
import '../../css/main.css';

class ParkingMeter extends React.Component {
    constructor(props) {
        super(props);
        this.tick = this.tick.bind(this);
        this.state = { remainingTime: moment.duration(this.props.expiry.diff(moment())) };
    }

    static getDerivedStateFromProps(props, state) {
        return { remainingTime: moment.duration(props.expiry.diff(moment())) };
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        if (this.state.remainingTime > 0) {
            this.setState({
                remainingTime: moment.duration(this.props.expiry.diff(moment()))
            });
        }
    }

    getHourString(){
        if (this.state.remainingTime.get('hours') < 10){
            return "0"+this.state.remainingTime.get('hours');
        }
        return this.state.remainingTime.get('hours');
    }

    getMinuteString(){
        if (this.state.remainingTime.get('minutes') < 10){
            return "0"+this.state.remainingTime.get('minutes');
        }
        return this.state.remainingTime.get('minutes');
    }

    getSecondString(){
        if (this.state.remainingTime.get('seconds') < 10){
            return "0"+this.state.remainingTime.get('seconds');
        }
        return this.state.remainingTime.get('seconds');
    }

    strSpace(i){
        return Array(i).fill('\xa0').join('')
    }

    render() {

        if (this.state.remainingTime > 0) {
            return (
                <section className="parking-meter">
                    <div className="container">
                        <div className="remaining-time">
                            Time Remaining
                        </div>
                        <div className="remaining-time-countdown">
                        {this.getHourString()}{this.strSpace(1)}{this.getMinuteString()}{this.strSpace(1)}{this.getSecondString()} 
                        </div>
                        <div className="hr-min-s">
                        {this.strSpace(3)}hours{this.strSpace(6)}minutes{this.strSpace(5)}seconds{this.strSpace(2)} 
                         </div>
                        <div className="remaining-time-expiry">
                            Expires {this.props.expiry.format("hh:mm A")}
                        </div>
                        <div className="spot-info">
                            <div className="lot-id">Lot ID: {this.props.lotName}</div>
                            <div className="spot-id">Spot ID: {this.props.spotName}</div>
                        </div>
                    </div>
                </section>
            );
        } else {
            return (
                <section className="parking-meter">
                    <div className="container">
                        <div className="remaining-time-expired">
                            Time Remaining
                        </div>
                        <div className="remaining-time-countdown-expired">
                        {"00"}{this.strSpace(1)}{"00"}{this.strSpace(1)}{"00"} 
                        </div>
                        <div className="hr-min-s-expired">
                        {this.strSpace(3)}hours{this.strSpace(6)}minutes{this.strSpace(5)}seconds{this.strSpace(2)} 
                        </div>
                        <div className="remaining-time-expiry-expired">
                            EXPIRED
                        </div>
                        <div className="spot-info">
                            <div className="lot-id">Lot ID: {this.props.lotName}</div>
                            <div className="spot-id">Spot ID: {this.props.spotName}</div>
                        </div>
                    </div>
                </section>
            );
        }

    }

}

export { ParkingMeter }